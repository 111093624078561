import { useUrls } from '@/composables/useUrls';
import { useApiProperties } from '~/composables/useApiProperties';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getPropertyByPropertyId } = useApiProperties();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE PROPERTY WITH CALL TO SPAPI
    const locationObj = await getPropertyByPropertyId(urlParams.propertyId, false);

    if (locationObj?.id) {
      // PROPERTY SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const propertyPageUrlForCurrentLang = useUrl.getPropertyPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          propertyId: locationObj.id,
          urlFriendlyProperty: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const propertyPagePathForCurrentLang = new URL(propertyPageUrlForCurrentLang).pathname.toLowerCase();

      if (propertyPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(propertyPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // PROPERTY IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Property '${urlParams.propertyId}' (${urlParams?.propertyName})`;
      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlPath),
      });
    }
  }
});
